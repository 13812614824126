import React from "react";
import { mapping, IconSVG } from "@aim-mf/styleguide";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

class MatrixImpactExplanation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    cellRender = (cell, props) => {
        let dataItemArray = props.dataItem[props.field].split("\n");
        if (props.field === "Parameters") {
            if (props.dataItem.Parameters === "Reputation") {
                if (
                    props.dataItem.Insignificant.startsWith("Incidents and/or ")
                ) {
                    return (
                        <td rowSpan={2}>
                            <div
                                style={{ width: "fit-content", margin: "auto" }}
                            >
                                {props.dataItem[props.field].toString()}
                            </div>
                        </td>
                    );
                } else {
                    return null;
                }
            }
        }
        return (
            <td>
                {dataItemArray.map((data) => {
                    return (
                        <div style={{ width: "fit-content", margin: "auto" }}>
                            {data}
                        </div>
                    );
                })}
            </td>
        );
    };

    render() {
        return (
            <div style={RiskCreationFormWrapStyle}>
                <div style={RiskCreationFormStyle}>
                    <div style={formBgStyle}>
                        <div style={{ margin: "100px" }}>
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div
                                className={"div_hover"}
                                style={{
                                    display: "inline-flex",
                                    marginLeft: "1000px",
                                }}
                                onClick={this.props.handleInformationClose}
                            >
                                <IconSVG
                                    name={"cancel"}
                                    color={mapping["Color_Basic_Light"]}
                                    size={"2.0"}
                                />
                            </div>

                            <div style={FormTitleStyle}> impact parameters</div>
                            <Grid
                                style={{
                                    height: "fit-content",
                                    width: "1000px",
                                }}
                                data={this.props.data}
                                cellRender={this.cellRender}
                            >
                                <Column field="Parameters" title="Parameters" />
                                <Column
                                    field="Insignificant"
                                    title="Insignificant"
                                />
                                <Column field="Minor" title="Minor" />
                                <Column field="Moderate" title="Moderate" />
                                <Column field="Major" title="Major" />
                                <Column field="Severe" title="Severe" />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const RiskCreationFormStyle = {
    position: "relative",
    left: "-50%",
};
const RiskCreationFormWrapStyle = {
    position: "absolute",
    left: "50%",
    top: "13.3rem",
};
const formBgStyle = {
    height: "fit-content",
    width: "fit-content",
    backgroundColor: mapping["Color_Extra_Half_Transparent"],
    display: "inline-flex",
    position: "relative",
};
const FormTitleStyle = {
    ...mapping["heading/h5/lightleft"],
    marginBottom: "15px",
};
export { MatrixImpactExplanation };
