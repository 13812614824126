import React from "react";
import { mapping, BarChart, Pill, StackBarChart } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

let KRIPeriodCount = 6;

class Charts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getFields = (risks, field) => {
        let controls = [];
        risks.forEach((risk) => {
            risk[field].forEach((control) => {
                controls.push(control);
            });
        });
        return controls;
    };

    render() {
        let chartMax = 10;
        let controlList = this.getFields(this.props.riskList, "controls");
        let APList = this.getFields(this.props.riskList, "actionplans");
        let KRIList = this.getFields(this.props.riskList, "KRIs");
        let riskAppetiteList = this.getFields(
            this.props.riskList,
            "riskappetite"
        );
        // console.log(["control", controlList]);
        // console.log(["APList", APList]);
        // console.log(["KRIList", KRIList]);
        // console.log(["riskAppetiteList", riskAppetiteList]);

        let controlIndependentSource = 0;
        let controlManagementSource = 0;

        controlList.forEach((control) => {
            if (
                control.is_ia_assurance ||
                control.is_other_assurance ||
                (control.type === "manual" && control.is_CSA_completed)
            ) {
                controlIndependentSource = controlIndependentSource + 1;
            } else if (
                control.type === "automatic" ||
                control.is_management_assurance
            ) {
                controlManagementSource = controlManagementSource + 1;
            }
        });

        let completedActionPlan = APList.filter((AP) => {
            let TodayDate = new Date();
            if (AP.completed_date !== null) {
                return (
                    Date.parse(AP.completed_date) >
                    TodayDate.setMonth(TodayDate.getMonth() - 6)
                );
            }
        });

        return (
            <div style={ChartArrayWrapperStyle}>
                <DashboardChartBlock
                    title={"Control Strength"}
                    TopbarSection={1}
                    topBarContent={controlList.length + " Control in Document"}
                    data={ControlStrengthChartData(controlList)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
                {/*<DashboardChartBlock*/}
                {/*    title={"Control Sources of assurance"}*/}
                {/*    TopbarSection={2}*/}
                {/*    topBarData={[*/}
                {/*        controlManagementSource,*/}
                {/*        controlIndependentSource,*/}
                {/*    ]}*/}
                {/*    topBarFootnote={["Mgmt Assurance", "Independent Assurance"]}*/}
                {/*    data={[*/}
                {/*        ControlSourceChartData(controlList),*/}
                {/*        ControlSourceChartComplementaryData(controlList),*/}
                {/*    ]}*/}
                {/*    //max={controlList.length}*/}
                {/*    height={"11.9rem"}*/}
                {/*    stack={true}*/}
                {/*    footNote={"Total number of controls: " + controlList.length}*/}
                {/*/>*/}
                <DashboardChartBlock
                    title={"Action Plans"}
                    TopbarSection={1}
                    topBarContent={
                        completedActionPlan.length +
                        " action plan completed in past 6 Month"
                    }
                    data={ActionPlanChartData(APList)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
                <DashboardChartBlock
                    title={"key risk indicators"}
                    TopbarSection={1}
                    topBarContent={"14 KRI followup actions"}
                    pillFootnote={"Last collected on March 2019"}
                    data={KRIChartData(KRIList)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
                <DashboardChartBlock
                    title={"Key Tolerance"}
                    TopbarSection={1}
                    topBarContent={
                        riskAppetiteList.length + " Tolerance Metrics"
                    }
                    data={KeyToleranceChartData(riskAppetiteList)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
            </div>
        );
    }
}

const DashboardChartBlock = (props) => {
    return (
        <div style={ChartWrapperStyle}>
            <div style={sectionTitleStyle}>{props.title}</div>
            {props.TopbarSection === 2 ? (
                <ProgressPill
                    topBarData={props.topBarData}
                    topBarDataText={props.topBarFootnote}
                />
            ) : (
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Pill
                            pillStyle={{
                                backgroundColor: "#4E5C75",
                                height: "26.25px",
                                width: "262.5px",
                            }}
                            contentStyle={topBarContentStyle}
                            content={props.topBarContent}
                        />
                    </div>
                    {props.pillFootnote ? (
                        <div
                            style={{
                                ...topBarFootnoteFontStyle,
                                marginTop: "10px",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {props.pillFootnote}
                        </div>
                    ) : (
                        <div style={{ height: "24px" }} />
                    )}
                </div>
            )}
            {!props.stack && (
                <BarChart
                    max={props.max}
                    height={props.height}
                    data={props.data}
                />
            )}
            {props.stack && (
                <StackBarChart
                    max={props.max}
                    height={props.height}
                    data={props.data}
                />
            )}
            {props.footNote && (
                <div style={footNoteStyle}>{props.footNote}</div>
            )}
        </div>
    );
};

const ProgressPill = (props) => {
    const totalWidth = 262.5;
    const leftWidth = Math.max(
        (totalWidth * props.topBarData[0]) /
            Math.max(props.topBarData[0] + props.topBarData[1], 1),
        20
    );
    const rightWidth = totalWidth - leftWidth;
    const LeftPartStyle = {
        ...topBarContentStyle,
        display: "inline-flex",
        height: "26.25px",
        width: leftWidth + "px",
        borderRadius: "105px 0 0 105px",
        backgroundColor: "#007ADC",
    };

    const RightPartStyle = {
        ...topBarContentStyle,
        display: "inline-flex",
        height: "26.25px",
        width: rightWidth + "px",
        borderRadius: "0 105px 105px 0",
        backgroundColor: "#4E5C75",
    };
    return (
        <div>
            <div style={{ margin: "auto", width: "263px" }}>
                <div style={LeftPartStyle}>{props.topBarData[0]}</div>
                <div style={RightPartStyle}>{props.topBarData[1]}</div>
            </div>
            <div
                style={{
                    display: "inline-flex",
                    marginLeft: "10px",
                    width: "263px",
                }}
            >
                <div style={{ ...topBarFootnoteFontStyle, marginLeft: "10px" }}>
                    {props.topBarDataText[0]}
                </div>
                <div style={{ ...topBarFootnoteFontStyle, marginRight: 0 }}>
                    {props.topBarDataText[1]}
                </div>
            </div>
        </div>
    );
};

const ControlStrengthChartData = (controlList) => {
    let strongControlNumber = controlList.filter((control) => {
        return control.strength === "Strong";
    }).length;

    let moderateControlNumber = controlList.filter((control) => {
        return control.strength === "Moderate";
    }).length;

    let weakControlNumber = controlList.filter((control) => {
        return control.strength === "Weak";
    }).length;

    let ControlStrengthChartData = [
        {
            strength:
                "Strong " +
                Math.round(
                    (strongControlNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: strongControlNumber,
            color: mapping["Color_Platform_RiskLow"],
            outline: "3px solid #00A6FF",
        },
        {
            strength:
                "Moderate " +
                Math.round(
                    (moderateControlNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: moderateControlNumber,
            color: mapping["Color_Platform_RiskMed"],
        },
        {
            strength:
                "Weak " +
                Math.round(
                    (weakControlNumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: weakControlNumber,
            color: mapping["Color_Platform_RiskHigh"],
        },
    ];
    return ControlStrengthChartData;
};

const ControlSourceChartComplementaryData = (controlList) => {
    let ControlCSACompleteNumber = controlList.filter((control) => {
        return control.is_CSA_performed;
    }).length;

    let ControlIANumber = controlList.filter((control) => {
        return control.is_ia_assurance;
    }).length;

    let ControlOtherNumber = controlList.filter((control) => {
        return control.is_other_assurance;
    }).length;
    let ControlSourceChartComplementaryData = [
        {
            strength:
                "CSA Performed\n" +
                Math.round(
                    (ControlCSACompleteNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: controlList.length - ControlCSACompleteNumber,
            color: "#EF8945",
            outline: "3px solid #00A6FF",
        },
        {
            strength:
                "Internal Audit\nPerformed " +
                Math.round(
                    (ControlIANumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: controlList.length - ControlIANumber,
            color: "#EF8945",
        },
        {
            strength:
                "Other\nAssurance" +
                Math.round(
                    (ControlOtherNumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: controlList.length - ControlOtherNumber,
            color: "#EF8945",
        },
    ];
    return ControlSourceChartComplementaryData;
};

const ControlSourceChartData = (controlList) => {
    // console.log(controlList)
    let ControlCSACompleteNumber = controlList.filter((control) => {
        return control.is_CSA_performed;
    }).length;

    let ControlIANumber = controlList.filter((control) => {
        return control.is_ia_assurance;
    }).length;

    let ControlOtherNumber = controlList.filter((control) => {
        return control.is_other_assurance;
    }).length;

    let ControlSourceChartData = [
        {
            strength:
                "CSA Performed\n" +
                Math.round(
                    (ControlCSACompleteNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: ControlCSACompleteNumber,
            color: "#FFBF47",
            outline: "3px solid #00A6FF",
        },
        {
            strength:
                "Internal Audit\nPerformed " +
                Math.round(
                    (ControlIANumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: ControlIANumber,
            color: "#FFBF47",
        },
        {
            strength:
                "Other\nAssurance" +
                Math.round(
                    (ControlOtherNumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: ControlOtherNumber,
            color: "#FFBF47",
        },
    ];
    return ControlSourceChartData;
};

const ActionPlanChartData = (APList) => {
    let actionPlanChartData = [
        {
            status: "On Track",
            number: APList.filter((AP) => {
                return AP.status === "On Track";
            }).length,
            color: mapping["Color_Platform_RiskLow"],
        },
        {
            status: "Overdue",
            number: APList.filter((AP) => {
                return AP.status === "Overdue";
            }).length,
            color: mapping["Color_Platform_RiskHigh"],
        },
        {
            status: "In Progress",
            number: APList.filter((AP) => {
                return AP.status === "In Progress";
            }).length,
            color: "#C5D0DE",
        },
    ];
    return actionPlanChartData;
};

const KRIChartData = (KRIList) => {
    let green = 0,
        amber = 0,
        red = 0,
        notDue = 0;
    KRIList.map((kri) => {
        if (kri.value.length === 0) {
            notDue += 1;
        } else if (kri.safe_zone === "low") {
            let value = kri.value
                .sort((a, b) => {
                    return a.period > b.period ? 1 : -1;
                })
                .slice(-KRIPeriodCount);
            let redValue = value.filter((value) => {
                return value.value > kri.upper_threshold;
            });
            let amberValue = value.filter((value) => {
                return (
                    value.value < kri.upper_threshold &&
                    value.value > kri.lower_threshold
                );
            });
            if (redValue.length > 0) {
                red += 1;
            } else if (amberValue.length > 0) {
                amber += 1;
            } else {
                green += 1;
            }
        } else if (kri.safe_zone === "high") {
            let value = kri.value
                .sort((a, b) => {
                    return a.period > b.period ? 1 : -1;
                })
                .slice(-KRIPeriodCount);
            let redValue = value.filter((value) => {
                return value.value < kri.lower_threshold;
            });
            let amberValue = value.filter((value) => {
                return (
                    value.value > kri.lower_threshold &&
                    value.value < kri.upper_threshold
                );
            });
            if (redValue.length > 0) {
                red += 1;
            } else if (amberValue.length > 0) {
                amber += 1;
            } else {
                green += 1;
            }
        }
    });
    let KRIChartData = [
        {
            threshold: "Green\n ",
            number: green,
            color: mapping["Color_Platform_RiskLow"],
        },
        {
            threshold: "Amber\n ",
            number: amber,
            color: mapping["Color_Platform_RiskMed"],
        },
        {
            threshold: "Red\n",
            number: red,
            color: mapping["Color_Platform_RiskHigh"],
        },
        {
            threshold: "Not Due\n",
            number: notDue,
            color: "#7F7F7F",
        },
    ];
    return KRIChartData;
};

const KeyToleranceChartData = (riskAppetite) => {
    let KeyToleranceChartData = [
        {
            threshold: "Breaches in Risk Tolerance\n ",
            number: riskAppetite.length,
            color: "#922B8B",
        },
    ];
    return KeyToleranceChartData;
};

const ChartArrayWrapperStyle = {
    display: "inline-flex",
    marginTop: "60px",
    marginLeft: "170px",
};

const ChartWrapperStyle = {
    position: "relative",
    height: "287.7px",
    width: "299.25px",
    backgroundColor: "#2F3B52",
    boxShadow: "0 2px 11px 0 rgba(0,0,0,0.3)",
    marginRight: "20px",
};

const sectionTitleStyle = {
    ...mapping["Sub Text Tag/H7-blue-tag"],
    height: "42px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#00BEFF",
};

const topBarFootnoteFontStyle = {
    margin: "auto",
    height: "14px",
    width: "fit-content",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "10.5px",
    letterSpacing: "0",
    lineHeight: "14px",
};

const topBarContentStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
    margin: "auto",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "12.6px",
    fontWeight: "bold",
    letterSpacing: "0",
};

const footNoteStyle = {
    margin: "auto",
    height: "14px",
    width: "fit-content",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "10.5px",
    letterSpacing: "0",
    lineHeight: "14px",
    position: "absolute",
    bottom: "1px",
    right: "5px",
};

export { Charts };
